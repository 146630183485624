import * as React from "react";
import MasterLayout from "../layouts/master";
import AboutApp from "../components/teacher_school_form/app";
// import ScrollToBottom from "../components/teacher_school_form/arrow_bottom";
import VidyaBox from "../components/teacher_school_form/vidyabox";
import SEO from "../components/seo/seo";
import TeacherAndSchoolForm from "../components/teacher_school_form/form";
import { Stack, Text } from "@chakra-ui/react";

const IndexPage = (props) => {
    return (
        <>
            <SEO
                title="SunoKitaab is Free for Teachers and Schools"
            />
            <MasterLayout>
                {/* <ScrollToBottom /> */}
                <VidyaBox />
                <AboutApp />
                <TeacherAndSchoolForm />
            </MasterLayout>
        </>
    );
};

export default IndexPage;