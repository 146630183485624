import * as React from "react";
import {
  Flex,
  Text,
  Stack,
  StackDivider,
  Icon,
  Box,
  chakra,
  SimpleGrid,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import PlayStoreBadge from "../icons/playstore_badge";
import AppStoreBadge from "../icons/appstore_badge";
import { FaHandsHelping } from "react-icons/fa";
import { IoMdSchool } from "react-icons/io";

export default function AboutApp() {
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"} maxW={"full"}
    >
      <Stack
        alignItems={"center"}
        justifyContent={"center"} p={6} mt={"24px"} maxW={"6xl"}
        direction={{ base: "column-reverse", lg: "row", md: "column", sm: "column-reverse" }}
      >
        <Stack spacing={6}>
          <Text
            fontSize={{ lg: "3rem", md: "3rem", sm: "3rem", base: "4xl" }}
            fontWeight="bold"
            lineHeight="none"
            letterSpacing={{ base: "normal", md: "tight" }}
            color={"gray.900"}
          >
            Listen, Learn & Educate Bharat with{" "}
            <Text
              display={{ base: "block", lg: "inline" }}
              w="full"
              bgClip="text"
              bgGradient="linear(to-r, green.400,purple.500)"
              fontWeight="extrabold"
            >
              SunoKitaab
            </Text>
          </Text>
          <chakra.h3 color={"gray.500"}>
            CBSE, RBSE, WBBSE, NCERT Audio books mapped just according to your
            textbook and up to date. Download lecture to listen later offline, without internet or wifi.
          </chakra.h3>
          <AppFeatures />
          <Stack
            style={{
              marginTop: "30px",
            }}
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: "column", sm: "row" }}
          >
            <a href="https://sunokitaab.com/app">
              <PlayStoreBadge />
            </a>
            <a href="https://apps.apple.com/us/app/sunokitaab-ncert-audiobooks/id6444225774">
              <AppStoreBadge />
            </a>
          </Stack>
        </Stack>
        <Box maxW={{ md: "2xl" }} pb={{ base: 12, md: 0, lg: 0 }}>
          <StaticImage
            alt={"SunoKitaab App"}
            src={"../../images/mobiles.png"}
            objectFit={"cover"}
          />
        </Box>
      </Stack>
    </Stack>
  );
}


const AppFeatures = () => {
  const Feature = (props) => {
    return (
      <Box>
        <Icon
          boxSize={12}
          _light={{
            color: "brand.700",
          }}
          mb={4}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          {props.icon}
        </Icon>
        <chakra.h3
          mb={3}
          fontSize="lg"
          lineHeight="shorter"
          fontWeight="bold"
          _light={{
            color: "gray.900",
          }}
        >
          {props.title}
        </chakra.h3>
        <chakra.p
          lineHeight="tall"
          color="gray.600"
          _dark={{
            color: "gray.400",
          }}
        >
          {props.children}
        </chakra.p>
      </Box>
    );
  };

  return (
    <SimpleGrid
      columns={{
        base: 1,
        md: 2,
        lg: 2,
      }}
      spacing={10}
      py={12}
      mx="auto"
      bg="white"
      _dark={{
        bg: "gray.800",
      }}
    >
      <Feature
        title="Features and Benefits"
        icon={
          <FaHandsHelping />
        }
      >
        <UnorderedList>
          <ListItem>Wide range of audiobooks covering various subjects and topics</ListItem>
          <ListItem>Access to educational content from top publishers and educators</ListItem>
          <ListItem>Customizable playlists and easy search options</ListItem>
          <ListItem>Offline listening and multi device Login</ListItem>
        </UnorderedList>
      </Feature>

      <Feature
        title="Advantages of using SunoKitaab in School"
        icon={<IoMdSchool />
        }
      >
        <UnorderedList>
          <ListItem>Cost-effective solution to improve learning outcomes</ListItem>
          <ListItem>Enhances student engagement and interest in learning</ListItem>
          <ListItem>Supports diverse learning styles and needs</ListItem>
          <ListItem>Promotes independent learning and self-paced study</ListItem>
        </UnorderedList>
      </Feature>
    </SimpleGrid>
  );
};
