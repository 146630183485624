import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import favicon from "../../images/favicon.ico";

const Seo = ({ description, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description,
            image
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = title || site.siteMetadata.title;

  return (
    <Helmet
      title={defaultTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:url",
          content: "https://sunokitaab.com"
        },
        {
          property: `og:image`,
          content: site.siteMetadata.image,
        },
        {
          property: `og:image:width`,
          content: "512",
        },
        {
          property: `og:image:height`,
          content: "512",
        },
        {
          name: `twitter:card`,
          content: `${metaDescription}`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.social?.twitter || `@Sunokitaab`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
        <meta name="monetag" content="455c1a0c047bfe64662ef6d892f67a11" />
        <meta name="facebook-domain-verification" content="igql0ismxfhxjayerep4kttrw5ywi3" />
      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "SunoKitaab",
            "alternateName": "Suno Kitaab Private Limited",
            "url": "https://sunokitaab.com",
            "logo": "https://s3sunokitaab.s3.ap-south-1.amazonaws.com/site-asset/logo.png",
            "telephone" : "+91 9911703290",
            "email" : "info@sunokitaab.com",
            "address" : {
              "@type" : "PostalAddress",
              "streetAddress" : "307, Supreme Cowork DLF Mall",
              "addressLocality" : "Shalimar Bagh, Delhi",
              "addressCountry" : "India",
              "postalCode" : "110088"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "9911703290",
              "contactType": "customer service",
              "contactOption": "TollFree",
              "areaServed": "IN",
              "availableLanguage": ["en","Hindi"]
            },
            "sameAs": [
              "https://www.facebook.com/sunokitaab",
              "https://twitter.com/sunokitaab",
              "https://www.instagram.com/sunokitaab",
              "https://www.youtube.com/c/SunoKitaab",
              "https://in.linkedin.com/company/sunokitaab"
            ]
          }`}
      </script>
      <link rel="icon" href={favicon} />
    </Helmet>
  );
};

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
