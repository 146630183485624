import * as React from "react"
import {
    FormControl,
    FormLabel,
    Input,
    Select,
    Button,
    VStack,
    useToast,
    Box,
    chakra,
    Flex
} from "@chakra-ui/react"

const TeacherAndSchoolForm = () => {
    const [fullName, setFullName] = React.useState("")
    const [phoneNumber, setPhoneNumber] = React.useState("")
    const [schoolName, setSchoolName] = React.useState("")
    const [address, setAddress] = React.useState("")
    const [schoolContact, setSchoolContact] = React.useState("")
    const [schoolRelation, setSchoolRelation] = React.useState("")

    const toast = useToast()

    const handleSubmit = () => {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", 'https://sunokitaab.grevity.in/api/shared/teacher_and_schools', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhr.send(JSON.stringify({
            fullname: fullName,
            phone: phoneNumber,
            school: schoolName,
            address: address,
            schoolContact: schoolContact,
            schoolRelation: schoolRelation
        }));

        setFullName("")
        setPhoneNumber("")
        setSchoolName("")
        setAddress("")
        setSchoolContact("")

        toast({
            title: "Form submitted!",
            description: "Thank you for submitting the form. We will call you back for verification",
            status: "success",
            duration: 10000,
            isClosable: true,
        })
    }

    return (
        <Flex maxW={'full'} justifyContent={"center"}>
            <Box px={12} py={24} maxW={'6xl'}>
                <chakra.h2
                    mb={3}
                    fontSize={{
                        base: "3xl",
                        md: "4xl",
                    }}
                    fontWeight="extrabold"
                    textAlign={{
                        base: "center",
                        sm: "left",
                    }}
                    _light={{
                        color: "black",
                    }}
                    lineHeight="shorter"
                    letterSpacing="tight"
                >
                    Fill-up the Form
                </chakra.h2>
                <chakra.p
                    mb={6}
                    fontSize={{
                        base: "lg",
                        md: "xl",
                    }}
                    textAlign={{
                        base: "center",
                        sm: "left",
                    }}
                    color="gray.600"
                    _dark={{
                        color: "gray.500",
                    }}
                >
                    Fill this form to get <b>10 VidyaBox devices</b> and <b>INR 50,000 App credits</b> for you school absolutely free
                </chakra.p>
                <form style={{ margin: "0 auto" }}>
                    <VStack spacing="4">
                        <FormControl>
                            <FormLabel>Your Name</FormLabel>
                            <Input
                                type="text"
                                name="fullname"
                                value={fullName}
                                onChange={(event) => setFullName(event.target.value)}
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Phone Number</FormLabel>
                            <Input
                                type="tel"
                                name="phone"
                                value={phoneNumber}
                                onChange={(event) => setPhoneNumber(event.target.value)}
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>School Name</FormLabel>
                            <Input
                                type="text"
                                name="school"
                                value={schoolName}
                                onChange={(event) => setSchoolName(event.target.value)}
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Address</FormLabel>
                            <Input
                                type="text"
                                name="address"
                                value={address}
                                onChange={(event) => setAddress(event.target.value)}
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>School Contact</FormLabel>
                            <Input
                                type="text"
                                name="school_contact"
                                value={schoolContact}
                                onChange={(event) => setSchoolContact(event.target.value)}
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Relationship with School</FormLabel>
                            <Select
                                onChange={(event) => setSchoolRelation(event.target.value)}
                                placeholder='Choose Type of Relationship with School'>
                                <option value='teacher'>Teacher</option>
                                <option value='principal'>Principal</option>
                                <option value='chairperson'>Chair Person</option>
                                <option value='student'>Student</option>
                                <option value='other'>Other</option>
                            </Select>
                        </FormControl>
                        <Button onClick={handleSubmit} style={{ backgroundColor: "#34e174", color: "white", marginBottom: "28px" }} size='lg'>
                            Submit
                        </Button>
                    </VStack>
                </form>
            </Box>
        </Flex>
    )
}

export default TeacherAndSchoolForm
